













import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { AboutProjectMutations } from '@store/modules/about-project/Types';
import { namespace } from 'vuex-class';

const NSAboutProjects = namespace('storeAboutProject');

import './scss/PopupFiltersAboutProject.scss';

@Component({
    name: 'PopupFiltersAboutProject',
    components: {
        PopupFilters: () => import('@components/popups/PopupFilters.vue'),
    }
})

export default class PopupFiltersAboutProject extends Vue {
    $refs!: {
        popupFilter: HTMLFormElement,
    }

    @Prop({}) individualMembersList!: any[];
    @Prop({}) juristicMembersList!: any[];
    @Prop({}) filteredUsersCount!: number | null;
    @Prop({ default: false }) resetFiltersState!: boolean;

    @NSAboutProjects.Getter('filters') filters!: any[];
    @NSAboutProjects.Getter('filtersApply') filtersApply!: boolean;
    @NSAboutProjects.Mutation(AboutProjectMutations.M_SET_FILTERS) setFilters!: (payload: any[]) => void;
    @NSAboutProjects.Mutation(AboutProjectMutations.M_SET_FILTERS_APPLY) setFiltersApply!: (payload: boolean) => void;

    @Watch('individualMembersList')
    @Watch('juristicMembersList')
    watchJuristicMembersList(): void {
        this.checkDataForSetOptions();
    }

    @Watch('filteredUsersCount')
    watchCount(): void {
        if (this.filtersApply) {
            this.$emit('set-filters-preview', this.popupData);
        }
    }

    popupData: any[] = [
        {
            title: 'Организация',
            block: 'companies',
            type: 'select',
            key: 'name',
            options: [],
            selected: [],
        },{
            title: 'Статус',
            block: 'status',
            type: 'checkboxes',
            key: 'deactivationDate',
            options: [
                { label: 'Активен' }, { label: 'Неактивен' }
            ],
            selected: [],
        },{
            title: 'ФИО ответственного лица',
            block: 'name',
            type: 'select',
            key: 'name',
            options: [],
            selected: [],
        },{
            title: 'Роль ответственного лица',
            block: 'roleIndividual',
            type: 'select',
            key: 'role.name',
            options: [],
            selected: [],
        },{
            title: 'Роль юридического лица',
            block: 'roleJuristic',
            type: 'select',
            key: 'role.name',
            options: [],
            selected: [],
        },
    ];

    setPopupDataFromFilters() {
        this.filters.forEach((filter) => {
            this.popupData.forEach((data) => {
                if (data.block === filter.block && (data.type === 'checkboxes' || data.type === 'select')) {
                    data.selected = filter.selected;
                }
            })
        })
    }

    applyFilters() {
        this.setFiltersApply(true);
        this.$emit('set-filters-preview', this.popupData);
        this.closePopup();
    }

    checkDataForSetOptions() {
        if (this.juristicMembersList && this.juristicMembersList.length) {
            this.setOptions();
        }
    }

    cancelFilters() {
        this.setFiltersApply(false);

        this.popupData.forEach((item) => {
            if (item.type === 'checkboxes' || item.type === 'select') {
                item.selected = [];
            }
        });

        this.setFilters([]);
        if (this.$refs.popupFilter) {
            this.$refs.popupFilter.resetFilters();
        }
    }

    setOptions() {
        this.popupData.forEach((item) => {
            if (item.block === 'companies') {
                item.options = this.allCompanies();
            }
            if (item.block === 'name') {
                item.options = this.allMembersNames();
            }
            if (item.block === 'roleIndividual') {
                item.options = this.allIndividualPersonsRoles();
            }
            if (item.block === 'roleJuristic') {
                item.options = this.allJuristicPersonsRoles();
            }
        });
    }

    allCompanies(): any[] {
        const added: string[] = [];
        const output: any[] = [];
        if (this.juristicMembersList) {
            for (const item of this.juristicMembersList) {
                if (item.name && !added.includes(item.name)) {
                    added.push(item.name);
                    output.push({label: item.name});
                }
            }
        }
        return output;
    }

    allMembersNames(): any[] {
        const added: string[] = [];
        const output: any[] = [];
        if (this.individualMembersList) {
            for (const item of this.individualMembersList) {
                if (item.name && !added.includes(item.name)) {
                    added.push(item.name);
                    output.push({label: item.name});
                }
            }
        }
        return output;
    }

    allIndividualPersonsRoles(): any[] {
        const added: string[] = [];
        const output: any[] = [];
        if (this.individualMembersList) {
            for (const item of this.individualMembersList) {
                if (item.role && item.role.name && !added.includes(item.role.name)) {
                    added.push(item.role.name);
                    output.push({label: item.role.name});
                }
            }
        }
        return output;
    }

    allJuristicPersonsRoles(): any[] {
        const added: string[] = [];
        const output: any[] = [];
        if (this.juristicMembersList) {
            for (const item of this.juristicMembersList) {
                if (item.role && item.role.name && !added.includes(item.role.name)) {
                    added.push(item.role.name);
                    output.push({ label: item.role.name });
                }
            }
        }
        return output;
    }

    changeSelected(e, byPreview = false) {
        for (const item of this.popupData) {
            if (item.title === e.section) {
                item.selected = e.selected;
            }
        }
        this.updateStoreFilters();
        if (byPreview) {
            this.$refs.popupFilter.changeSelectedData(e);
        }
    }

    updateStoreFilters() {
        const filters: any[] = [];
        this.popupData.forEach((item) => {
            if ((item.type === 'checkboxes' || item.type === 'select') && item.selected.length) {
                filters.push(item);
            }
        });
        this.setFilters(filters);
    }

    openPopup() {
        this.$refs.popupFilter.openPopup();
    }

    closePopup() {
        this.$refs.popupFilter.closePopup();
    }
}
